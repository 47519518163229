<script>
export default {
  props: {
    message: { type: String, default: '' },
    action: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      toggleAfterTimeout: false,
    };
  },
  mounted() {},
  methods: {},
};
</script>

<template>
  <div>
    <div
      class="shadow-sm rounded-[4px] items-center gap-3 inline-flex mb-2 max-w-[25rem] min-h-[1.875rem] min-w-[15rem] px-6 py-3 text-left"
      :class="[
        type === 'error'
          ? 'bg-[#F02A50] text-white'
          : 'bg-[#a9deab] text-green-700',
      ]"
    >
      <div class="text-sm font-medium">
        {{ message }}
      </div>
      <div v-if="action">
        <router-link
          v-if="action.type == 'link'"
          :to="action.to"
          class="font-medium cursor-pointer select-none text-woot-500 dark:text-woot-500 hover:text-woot-600 dark:hover:text-woot-600"
        >
          {{ action.message }}
        </router-link>
      </div>
    </div>
  </div>
</template>
