import { render, staticRenderFns } from "./OAuthChannel.vue?vue&type=template&id=2770c5c5&"
import script from "./OAuthChannel.vue?vue&type=script&setup=true&lang=js&"
export * from "./OAuthChannel.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports